<template>
    <div class="detail-form">
        <!-- 标题 -->
        <div class="detail-form-title">LEICA/徕卡 M10-P经典旁轴数码相机 免费个性化饰皮定制服务</div>
        <!-- 价格 -->
        <div class="detail-form-item">
            <!-- 标题 -->
            <div class="detail-form-item-title">价格：</div>
            <!-- 内容 -->
            <div class="detail-form-item-content detail-form-item-price">
                <span class="form-price">61000.00</span>
                <span>元</span>
            </div>
        </div>
        <!-- 邮费 -->
        <div class="detail-form-item">
            <!-- 标题 -->
            <div class="detail-form-item-title">邮费：</div>
            <!-- 内容 -->
            <div class="detail-form-item-content">全国包邮（不含港澳台地区、西藏、新疆、内蒙古、宁夏、青海）</div>
        </div>
        <!-- 数据 -->
        <div class="detail-form-data">
            <!-- 月销量 -->
            <div>
                <span>月销量：</span>
                <span class="detail-form-data-num">7889</span>
            </div>
            <!-- 累计评价数 -->
            <div>
                <span>累计评价数：</span>
                <span class="detail-form-data-num">27889</span>
            </div>
            <!-- 好评率 -->
            <div>
                <span>好评率：</span>
                <span class="detail-form-data-num">97.3%</span>
            </div>
        </div>
        <!-- 颜色 -->
        <div class="detail-form-item">
            <!-- 标题 -->
            <div class="detail-form-item-title">颜色：</div>
            <!-- 内容 -->
            <ul class="detail-form-item-color">
                <li v-for="item in data_color"
                    :key="item.id">
                    <a href="javascript:;"
                        @click="handleColor(item.value)"
                        :class="{
                            'selected-color': item.value == selected_color
                        }">
                        {{item.label}}
                    </a>
                </li>
            </ul>
        </div>
        <!-- 套餐 -->
        <div class="detail-form-item">
            <!-- 标题 -->
            <div class="detail-form-item-title">套餐：</div>
            <!-- 内容 -->
            <ul class="detail-form-item-color">
                <li v-for="item in data_package"
                    :key="item.id">
                    <a href="javascript:;"
                        @click="handlePackage(item.value)"
                        :class="{
                            'selected-color': item.value == selected_package
                        }">
                        {{item.label}}
                    </a>
                </li>
            </ul>
        </div>
        <!-- 数量 -->
        <div class="detail-form-item">
            <!-- 标题 -->
            <div class="detail-form-item-title">数量</div>
            <!-- 内容 -->
            <div class="detail-form-item-content detail-form-item-num">
                <!-- 计数器 -->
                <el-input-number
                    v-model="num"
                    size="small"
                    :min="1">
                </el-input-number>
                <!-- 库存 -->
                <div class="detail-form-item-stock">库存79346件</div>
            </div>
        </div>
        <!-- handler -->
        <div class="detail-form-handler">
            <el-button
                size="small"
                type="primary"
                class="detail-form-handle-add"
                plain>
                加入购物车
            </el-button>
            <el-button
                size="small"
                type="primary"
                class="detail-form-handle-buy">
                立即购买
            </el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selected_color: "",
                selected_package: "",
                num: 1,
                data_color: [
                    {
                        value: 1,
                        label: "白色版"
                    },
                    {
                        value: 2,
                        label: "ASC 100周年版"
                    },
                    {
                        value: 3,
                        label: "黑色"
                    },
                    {
                        value: 4,
                        label: "银色"
                    },
                    {
                        value: 5,
                        label: "暖橙"
                    },
                    {
                        value: 6,
                        label: "青空"
                    },
                    {
                        value: 7,
                        label: "象牙白"
                    }
                ],
                data_package: [
                    {
                        value: 1,
                        label: "套餐一"
                    },
                    {
                        value: 2,
                        label: "套餐二"
                    },
                    {
                        value: 3,
                        label: "套餐三"
                    },
                    {
                        value: 4,
                        label: "套餐四"
                    },
                    {
                        value: 5,
                        label: "官方标配"
                    }
                ]
            }
        },
        methods: {
            handleColor(val){
                this.selected_color = val;
            },
            handlePackage(val){
                this.selected_package = val;
            }
        },
    }
</script>

<style scoped>
.detail-form{
    padding: 30px;
}
.detail-form > div + div{
    margin-top: 30px;
}

/* 主标题 */
.detail-form-title{
    font-size: 20px;
}

/* 项容器 */
.detail-form-item{
    display: flex;
}
/* 项标题 */
.detail-form-item-title{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: 50px;
    padding: 4px 0;
}
/* 项内容 */
.detail-form-item-content{
    padding: 4px 0;
}

/* 价格 */
.detail-form-item-price{
    display: flex;
    align-items: center;
    padding: 0;
}
.form-price{
    margin-left: 60px;
    margin-right: 6px;
    font-size: 22px;
    color: var(--color);
}

/* 数据 */
.detail-form-data{
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    background-color: var(--color2);
    border-radius: 6px;
}
.detail-form-data-num{
    font-size: 16px;
    color: var(--color);
}

/* 颜色 */
.detail-form-item-color{
    display: flex;
    flex-wrap: wrap;
}
.detail-form-item-color a{
    display: block;
    padding: 4px 12px;
    border-radius: 30px;
    color: #303133;
}
.detail-form-item-color a.selected-color{
    background-color: var(--color);
    color: #fff;
}

/* 数量 */
.detail-form-item-num{
    display: flex;
    align-items: center;
}
/* 库存 */
.detail-form-item-stock{
    margin-left: 20px;
    color: #C0C4CC;
}

/* 加入 8db5fc */
.detail-form-handler > button + button{
    margin-left: 30px;
}
.detail-form-handle-add,
.detail-form-handle-buy{
    padding: 10px 60px;
    border: 2px solid var(--color);
}
.detail-form-handle-add{
    background-color: var(--color2);
    color: var(--color);
}
.detail-form-handle-add:hover,
.detail-form-handle-add:active,
.detail-form-handle-add:focus{
    background-color: var(--color);
    border-color: var(--color);
}
.detail-form-handle-buy{
    background-color: var(--color);
}
.detail-form-handle-buy:hover,
.detail-form-handle-buy:active,
.detail-form-handle-buy:focus{
    background-color: var(--color3);
    border-color: var(--color3);
}
.detail-form-handle-add >>> span,
.detail-form-handle-buy >>> span{
    font-size: 18px;
    letter-spacing: 2px;
}
</style>